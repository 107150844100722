//quick and the dirty...
(function($){
  'use strict';
  function init() {
    $('.js-nav-toggle').click(function(event) {
      event.preventDefault();
      var text = $(this).html();
      $('#main-container').toggleClass('is-hide-nav');


      //The quick and the dirty...
      if ( text === 'Hide Nav') {
        $(this).html('Show Nav');
      } else {
        $(this).html('Hide Nav');
      }
    });

    $('.js-hide-code').click(function(e){
      e.preventDefault();
      $('.html').addClass('collapsed');
    });
    $('.html').on('click', function(e){
      $(this).removeClass('collapsed');
    });

    $('.html').addClass('collapsed');

    //Chatbox override
    $('.chatbox').addClass('is-library');
    $('.library-chatbox-toggle').click(function(event) {
      event.preventDefault();
      $('.chatbox').toggleClass('is-visible-library');
    });

    $('.is-library .js-chatbox-close').click(function(event) {
      event.preventDefault();
      $('.chatbox').removeClass('is-visible is-visible-library');
    });

  }

  $(document).on('ready', init);

})(jQuery);

